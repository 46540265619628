import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';

function Landing(props) {
  const {landing} = props;

  return <Wrapper />;
}

const Wrapper = styled.div`
  margin-bottom: 80px;
  padding-top: var(--topNavBarHeight);
`;

export default Landing;
