import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';

function FundraisingSection(props) {
  const {landing, products} = props;
  return (
    <Wrapper>
      <div className="container">
        <div className="title-container">
          <h2 className="title">募資商品</h2>
          <h2 className="subtitle">Fundraising</h2>
        </div>
        <div className="content-container">
          {(products || []).map((product, index) => (
            <ProductItem
              key={index}
              onClick={() => AppActions.navigate(`/product?id=${product.id}`)}>
              <div className="frame">
                <img alt="product-img" src={product.images[0]?.expected_url} />
              </div>
              <div className="info">
                <h3 className="name">{product.name}</h3>
                <h3 className="price">
                  {!!product.original_price && product.original_price !== 0 && (
                    <span className="origin">${product.original_price}</span>
                  )}
                  <span>${product.price}</span>
                </h3>
              </div>
            </ProductItem>
          ))}
        </div>
        <div className="button-container">
          <PrimaryButton
            onClick={() => AppActions.navigate('/products?cat=募資商品')}>
            查看全部募資
          </PrimaryButton>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;

  & > .container {
    margin: 0 auto;
    max-width: var(--contentMaxWidth);
    width: 100%;

    & > .content-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    & > .button-container {
      display: flex;
      justify-content: center;
      padding-top: 40px;
    }
  }
`;

const PrimaryButton = styled.button`
  background-color: var(--primaryColor);
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  }

  &:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
`;

const ProductItem = styled.div`
  margin: 10px;
  max-width: 270px;
  width: 100%;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);

  & > div.frame {
    overflow: hidden;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background-color: #ececea;

    & > img {
      width: 270px;
      height: 270px;
      object-fit: contain;
      transition: all 200ms;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }

  & > div.info {
    padding: 10px;

    & > h3.name {
      margin-bottom: 25px;
      font-size: 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    & > h3.price {
      text-align: right;
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--primaryColor);

      & > .origin {
        margin-right: 5px;
        text-decoration: line-through;
        font-size: 1rem;
        color: #cbcbcb;
      }
    }
  }

  // (270+20)*4 = 1160
  @media screen and (max-width: ${1160 - 1}px) {
    max-width: 240px;

    & > div.frame {
      & > img {
        width: 240px;
        height: 240px;
      }
    }
  }

  // (240+20)*4 = 1040
  @media screen and (max-width: ${1040 - 1}px) {
    max-width: 210px;

    & > div.frame {
      & > img {
        width: 210px;
        height: 210px;
      }
    }
  }

  // (210+20)*4 = 920
  @media screen and (max-width: ${920 - 1}px) {
    max-width: 180px;

    & > div.frame {
      & > img {
        width: 180px;
        height: 180px;
      }
    }
  }

  // (180+20)*4 = 800
  @media screen and (max-width: ${800 - 1}px) {
    max-width: 150px;

    & > div.frame {
      & > img {
        width: 150px;
        height: 150px;
      }
    }
  }

  // max: (150+20)*4 = 680
  @media screen and (max-width: ${680 - 1}px) {
    max-width: 270px;

    & > div.frame {
      & > img {
        width: 270px;
        height: 270px;
      }
    }
  }

  // (270+20)*2 = 580
  @media screen and (max-width: ${580 - 1}px) {
    max-width: 240px;

    & > div.frame {
      & > img {
        width: 240px;
        height: 240px;
      }
  }

  // (240+20)*2 = 520
  @media screen and (max-width: ${580 - 1}px) {
    max-width: 210px;

    & > div.frame {
      & > img {
        width: 210px;
        height: 210px;
      }
    }
  }

  //(210+20)*2 = 460
  @media screen and (max-width: ${460 - 1}px) {
    max-width: 180px;

    & > div.frame {
      & > img {
        width: 180px;
        height: 180px;
      }
    }
  }

  // (180+20)*2 = 400
  @media screen and (max-width: ${400 - 1}px) {
      max-width: 150px;

    & > div.frame {
      & > img {
        width: 150px;
        height: 150px;
      }
    }
  }
`;

export default FundraisingSection;
