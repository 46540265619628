import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../../AppActions';
import HeroBannerSection from './HeroBannerSection';
// ahoy custom section
import FundraisingSection from '../FundraisingSection';
import ArticleSection from './ArticleSection';
import ProductSection from './ProductSection';
import TopPromotionSection from './TopPromotionSection';
import BottomPromotionSection from './BottomPromotionSection';
import BeltSection from './BeltSection';

function Landing(props) {
  const {landing} = props;
  const [articles, setArticles] = React.useState(null);
  const [products, setProducts] = React.useState(null);
  const [fundraisings, setFundraisings] = React.useState(null);

  const fetchData = React.useCallback(async () => {
    if (!landing.type1_layout) {
      return;
    }

    AppActions.setLoading(true);
    try {
      //fetch products
      let productIds = landing.type1_layout.product_section?.products || [];
      let products = [];

      let _products = await JStorage.fetchDocuments(
        'product',
        {
          _id: {
            $in: [
              ...productIds.map((id) => ({
                $oid: id,
              })),
            ],
          },
        },
        null,
        null,
        null,
        {anonymous: true},
      );

      for (let id of productIds) {
        let p = _products.find((p) => p.id === id);
        products.push(p);
      }

      setProducts(products);

      //fetch articles
      let articleIds = landing.type1_layout.article_section?.articles || [];
      let articles = [];

      let _articles = await JStorage.fetchDocuments(
        'Article_Default',
        {
          _id: {
            $in: [
              ...articleIds.map((id) => ({
                $oid: id,
              })),
            ],
          },
        },
        null,
        null,
        null,
        {anonymous: true},
      );

      for (let id of articleIds) {
        let p = _articles.find((p) => p.id === id);
        articles.push(p);
      }

      setArticles(articles);

      //ahoy fetch fundraising
      let {results: _fundraisings} = await JStorage.fetchDocuments(
        'product',
        {ui_type: {$eq: 'fundraising'}},
        ['-created'],
        {
          offset: 0,
          limit: 4,
        },
        null,
        {anonymous: true},
      );

      setFundraisings(_fundraisings);
    } catch (err) {
      console.warn(err);
    }
    AppActions.setLoading(false);
  }, [landing.type1_layout]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper id="rev-LandingPage">
      <HeroBannerSection landing={landing} />
      {/* ahoy custom section  */}
      <FundraisingSection landing={landing} products={fundraisings} />
      <ArticleSection landing={landing} articles={articles} />
      <TopPromotionSection landing={landing} />
      <ProductSection landing={landing} products={products} />
      <BottomPromotionSection landing={landing} />
      <BeltSection landing={landing} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  min-height: var(--contentMinHeight);

  & .title-container {
    margin-bottom: 20px;
    text-align: center;
  }
`;

export default Landing;
